.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    header {
        max-width: 40.625rem;
        margin-inline: auto;
        text-align: center;

        p {
            color: var(--display-onlight-secondary);
        }
    }

    figcaption {
        color: var(--display-onlight-tertiary);
        text-align: center;
    }
}

.tagList {
    display: flex;
    gap: var(--space-12);
    justify-content: center;
    flex-wrap: wrap;
}

.banner {
    inline-size: 1088px;
    block-size: 454px;
    max-inline-size: 100%;
    height: auto;
    border-radius: var(--border-radius-sm);
    object-fit: cover;
}

.videoWrapper {
    display: grid;
    place-items: center;
    inline-size: 100%;
    max-inline-size: 802px;
    overflow: hidden;
    aspect-ratio: 16 / 9;
    border-radius: var(--border-radius-2xl);
    background-color: var(--ui-background);

    & > *,
    &::after {
        grid-area: 1 / 1;
    }

    .media {
        inline-size: 100%;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        block-size: auto;
    }

    .watchVideoBtn {
        box-shadow: var(--shadow-overlay);
    }
}

.colorSecondary {
    color: var(--display-onlight-secondary);
}
