.publisher {
    display: flex;
    align-items: center;

    a {
        color: var(--display-onlight-color-blue);
    }
}

.publisher img {
    border-radius: var(--border-radius-full);
    margin-inline-end: var(--space-16);
}

.bio {
    color: var(--display-onlight-secondary);
    margin-block-start: 2px;
}
