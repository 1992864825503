.callout {
    color: var(--display-onlight-secondary);

    a {
        color: var(--display-onlight-color-blue);
        text-decoration: none;
        font-weight: 500;

        &:hover {
            text-decoration: underline;
        }
    }
}
